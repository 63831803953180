<template>
  <div class="w-100">
    <label>{{ title }}</label>
    <b-form-select :value="value" @change="changed" :disabled="disabled">
      <b-form-select-option
        class="py-2"
        v-for="(item, idx) in options"
        :key="idx"
        :value="item.value"
        :disabled="item.disabled || false"
      >
        {{ item.description }}
      </b-form-select-option>
    </b-form-select>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    options: Array,
    value: { type: [String, Number] },
    disabled: Boolean
  },
  methods: {
    changed(selected) {
      this.$emit("input", selected);
      this.$emit("changed", selected);
    },
  },
};
</script>

<style>
</style>